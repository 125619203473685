import { render, staticRenderFns } from "./Affiliates.vue?vue&type=template&id=2854ed88&scoped=true"
import script from "./Affiliates.vue?vue&type=script&lang=js"
export * from "./Affiliates.vue?vue&type=script&lang=js"
import style0 from "./Affiliates.vue?vue&type=style&index=0&id=2854ed88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2854ed88",
  null
  
)

export default component.exports